.card {
  position: relative;
  background-color: $white;
  padding: 40px;
  border-radius: 6px;

  &.thin {
    padding: 24px;
  }
  .card-title {
    font-family: $body-font;
    color: $gray-5;
  }
  .card-header {
    margin-bottom: 20px;

    .search-form > .form-input {
      max-width: 240px;

      &:focus,
      &.has-content {
        max-width: 450px;
      }
    }
  }
  &.no-gutters {
    padding-left: 0;
    padding-right: 0;
  }
  & > .no-gutters {
    //margin-left: -40px;
    //margin-right: -40px;
    margin-left: -25px;
    margin-right: -25px;
  }
  &.thin > .no-gutters {
    margin-left: -9px;
    margin-right: -9px;

    .card-body {
      padding-left: 24px;
      padding-right: 24px;
    }
  }

  &.no-gutters .card-body,
  & > .no-gutters .card-body {
    padding-left: 40px;
    padding-right: 40px;
  }

  & > .table-responsive,
  .load-more-button {
    margin-left: -40px;
    margin-right: -40px;
    width: calc(100% + 80px);
  }
  .load-more-button:not(.button) {
    position: relative;
    display: inline-block;
    z-index: 1;
    background-color: $gray-3;
    min-width: 70px;
    padding: 10px 18px;
    color: $white;
    text-align: center;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
  .table {
    margin: 0;

    tr td,
    tr th {

      &:first-of-type {
        padding-left: 40px;
      }
      &:last-of-type {
        padding-right: 40px;
      }
    }
  }
}

// Drawer Card Vue component
.drawer-card {
  overflow: hidden;

  &.open {

    .toggle {

      span.line {

        &.line-1 {
          @include css3(transform, rotate(180deg));
        }
        &.line-2 {
          @include css3(transform, rotate(360deg));
        }
      }
    }
    .drawer-body {
      max-height: none;
    }
  }

  .drawer-body {
    max-height: 0;
  }
  .toggle {
    position: absolute;
    right: 12px;
    top: 12px;

    span.line {
      position: absolute;
      display: block;
      width: 20px;
      height: 4px;
      background-color: $gray-4;
      border-radius: 4px;

      @include css3(transition, all 0.15s ease);

      &.line-1 {
        left: 3px;
        top: 11px;
      }
      &.line-2 {
        left: 3px;
        top: 11px;
        @include css3(transform, rotate(90deg));
      }
    }
  }
}
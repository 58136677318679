// Backgrounds
.bg-primary {
  background-color: $primary !important;
}
.bg-white {
  background-color: $white !important;
}
.bg-green,
.bg-success {
  background-color: $green !important;
}
.bg-orange,
.bg-warning {
  background-color: $orange !important;
}
.bg-light-orange {
  background-color: $light-orange !important;
}
.bg-black {
  background-color: $black !important;
}
.bg-gray-1 {
  background-color: $gray-1 !important;
}
.bg-gray-2 {
  background-color: $gray-2 !important;
}
.bg-gray-3 {
  background-color: $gray-3 !important;
}
.bg-gray-4 {
  background-color: $gray-4 !important;
}
.bg-gray-5 {
  background-color: $gray-5 !important;
}

// Text
.font-display {
  font-family: $display-font;
}
.text-white {
  color: $white !important;
}
.text-body {
  color: $body-color !important;
}
.text-primary,
.text-blue {
  color: $primary !important;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.text-normal-case {
  text-transform: none;
}
.text-lighter {
  color: $gray-4 !important;
}
.text-green,
.text-success {
  color: $success !important;
}
.text-orange,
.text-danger {
  color: $danger !important;
}
.text-purple {
  color: $purple !important;
}
.text-gray-3 {
  color: $gray-3 !important;
}
.text-gray-4 {
  color: $gray-4 !important;
}
.text-gray-5 {
  color: $gray-5 !important;
}

// Border Radius
.border-radius-0 {
  border-radius: 0 !important;
}
.border-radius-1 {
  border-radius: .12rem !important;
}
.border-radius-2 {
  border-radius: .25rem !important;
}
.border-radius-3 {
  border-radius: .4rem !important;
}
.border-radius-top-right-0 {
  border-top-right-radius: 0 !important;
}
.border-radius-top-left-0 {
  border-top-left-radius: 0 !important;
}
.border-radius-bottom-right-0 {
  border-bottom-right-radius: 0 !important;
}
.border-radius-bottom-left-0 {
  border-bottom-left-radius: 0 !important;
}

// Alignment
.text-right {
  text-align: right !important;
}

// Font weight
.font-weight-medium {
  font-weight: 500 !important;
}
.font-weight-semi-bold {
  font-weight: 600 !important;
}

// Font size
.font-size-largest {
    font-size: 1.72rem;
}
.font-size-larger {
    font-size: 1.5rem;
}
.font-size-large {
  font-size: 1.24rem;
}
.font-size-small {
  font-size: .8rem;
}
.font-size-smaller {
  font-size: .63rem;
}

.fly-out {
  position: relative;
  display: inline-block;

  &.primary .fly-out-menu .menu-item,
  .fly-out-menu .menu-item.primary  {
    color: $primary;

    &:hover {
      background-color: $primary;
    }
  }
  &.bottom .fly-out-menu {
    bottom: auto;
    top: 100%;
  }
  .fly-out-menu {
    position: absolute;
    display: none;
    min-width: 255px;
    right: 0;
    bottom: calc(100% + 4px);
    background-color: $white;
    padding: 8px;
    color: $gray-4;
    z-index: 100;
    border-radius: 6px;
    overflow: hidden;

    @include css3(box-shadow, 0 0 10px 2px $gray-3);

    &.active {
      display: block;
    }

    .menu-item {
      display: block;
      margin: 0 -8px;
      border-bottom: 1px solid $gray-3;
      padding: 8px 14px;
      color: $gray-3;
      font-size: 1.15rem;
      width: calc(100% + 16px);
      text-align: left;

      &:hover {
        text-decoration: none;
        background-color: $gray-3;
        color: $gray-1
      }
      &:first-child {
        margin-top: -8px;
      }
      &:last-child {
        border-bottom: 0;
        margin-bottom: -8px;
      }
      &.danger {
        color: $gray-3 !important;

        &:hover {
          color: $gray-1 !important;
          background-color: $orange !important;
        }
      }
    }
  }
}
// Table Resets
.table {

  thead {

    th {
      font-family: $display-font;
      font-size: .8rem;
      color: $gray-3;
      letter-spacing: $heading-spacing;
      text-transform: uppercase;
      border-top: 0;
      border-bottom: 1px solid $gray-2;
    }
  }

  th,
  td {
    border-top: 1px solid $gray-2;
    color: $gray-5;
    padding: 0.85rem;
    letter-spacing: $body-spacing;

    &.vertical-align-middle {
      vertical-align: middle;
    }
    &.min-w-100 {
      min-width: 100px;
    }
    &.min-w-200 {
      min-width: 200px;
    }
    &.min-w-lg-200 {
      @include media-breakpoint-up(lg) {
        min-width: 200px !important;
      }
    }
  }
}
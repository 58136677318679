// Button Reset
button {
  padding: 0;
  margin: 0;
  border: 0;
  background-color: transparent;
  outline: 0;

  &:focus {
    outline: 0;
  }
}

// Button Base
button,
.button {
  position: relative;
  display: inline-block;
  z-index: 1;
  cursor: pointer;
  background-color: $gray-3;
  min-width: 70px;
  padding: 10px 18px;
  border-radius: 4px;
  border: 2px solid transparent;

  font-family: $body-font;
  color: $white;
  font-weight: 700;
  font-size: .8rem;
  letter-spacing: .05em;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;

  @include css3(transition, 0.1s background-color ease);
  &:disabled {
    opacity: .4;
    cursor: no-drop;
  }
  &:hover:not(:disabled) {
    background-color: $gray-2;
    text-decoration: none;
    color: $white;
  }
}

// Primary Button
button.primary,
.button.primary {
  background-color: $primary;

  &:hover {
    color: $white;
    background-color: $dark-blue;
  }
}

// Secondary Button
button.secondary,
.button.secondary {
  background-color: transparent;
  color: $gray-4;

  &:hover {
    background-color: $gray-2;
  }
  // Edge case
  &.primary {
    color: $primary;

    &:hover {
      background-color: $light-blue;
    }
  }
}

// Danger Button
button.danger,
.button.danger,
button.orange,
.button.orange {
  background-color: $orange;

  &:hover {
    color: $white;
    background-color: $dark-orange;
  }
}

// Success Button
button.success,
.button.success,
button.green,
.button.green {
  background-color: $green;

  &:hover {
    color: $white;
    background-color: $dark-green;
  }
}

// Outline Button
button.outline,
.button.outline {
  background-color: transparent;
  border: 2px solid $gray-3;

  &:hover {
    color: $white;
    background-color: $gray-3;
  }
}

button.outline-white,
.button.outline-white {
  background-color: transparent;
  border: 2px solid $white;

  &:hover {
    color: $primary;
    background-color: $white;
  }
}

// Tiny Button
button.tiny,
.button.tiny {
  padding: 6px 14px;
}

// Large Button
button.large,
.button.large {
  padding: 10px 28px;
}

// X-Large Button
button.x-large,
.button.x-large {
  padding: 13px 40px;
}

// thin Button
button.thin,
.button.thin {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

// Plain Button; Removes all default styling
button.plain,
.button.plain {
  border-radius: 0;
  min-width: auto;
  padding: 0;
  margin: 0;
  background-color: transparent;
  font-size: inherit;
  font-weight: inherit;
  text-transform: inherit;
  text-align: left;

  &:hover {
    background-color: transparent;
    color: inherit;
  }
}

// Block Button
button.block,
.button.block {
  display: block;
  text-align: center;
  width: 100%;
}

// Button With Icon
button.w-icon,
.button.w-icon {
  display: inline-flex;
  align-items: center;

  & > .gps-icon {
    order: 0;
    margin-right: 6px;
  }
  &.icon-right {

    & > .gps-icon {
      order: 2;
      margin-right: auto;
      margin-left: 6px;
    }
  }
}

// Icon Button
button.icon,
.button.icon {
  width: 30px;
  height: 30px;
  background-position: center center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  // reset
  padding: 0;
  min-width: auto;
  background-color: transparent !important;

  @include css3(transition, opacity 0.2s ease);

  &:hover {
    opacity: .4;
  }
  &.trash {
    background-image: url('#{$image-path}delete.svg');

    // pre-loads image
    &:after {
      display: none;
      content: '';
      background: url('#{$image-path}delete-open.svg');
    }
    &:hover {
      opacity: 1;
      background-image: url('#{$image-path}delete-open.svg');
    }
  }
  &.left-arrow {
    background-image: url('#{$image-path}left-arrow.svg');
  }
  &.left-arrow-gray {
    background-image: url('#{$image-path}left-arrow-gray.svg');
  }
  &.right-arrow {
    background-image: url('#{$image-path}right-arrow.svg');
  }
  &.right-arrow-gray {
    background-image: url('#{$image-path}right-arrow-gray.svg');
  }
}

// Circle Button
button.circle,
.button.circle {
    width: 42px;
    height: 42px;
    text-align: center;
    border-radius: 21px;
    padding: 10px;
    min-width: auto;

    &.small {
        padding: 8px;
        height: 30px;
        width: 30px;
    }
    &.blue {
        background-color: $blue;
        color: $white;

        &:hover,
        &:active {
            background-color: darken($blue, 10%);
        }
    }
}

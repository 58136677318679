.sort-filter {
  position: relative;

  .wrapper {
    position: relative;
    cursor: pointer;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
  }
  .icon {
    width: 26px;
    height: 24px;
    margin-right: 10px;
    background: url('#{$image-path}sort.svg') center center no-repeat;
  }
  .label {
    text-transform: uppercase;
    color: $gray-4;
    font-weight: 700;
    font-size: .9rem;
    letter-spacing: $label-spacing;
  }
  .caret {
    position: relative;
    margin-left: 20px;
    width: 15px;
    height: 24px;
    background: url('#{$image-path}dropdown.svg') center center no-repeat;
    background-size: 24px;
  }
  &.sort-boxes {

    .fly-out .fly-out-menu {
      min-width: 300px;
    }
  }
}
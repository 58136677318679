// Free Account Block
.free-account {
  padding: 14px 30px;
  border-radius: 4px;
  background-color: $primary;

  @include media-breakpoint-up(md) {
    padding: 26px 40px;
  }
}

// Package card
.package {

  .price {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    font-family: $display-font;
    text-transform: uppercase;
    line-height: 1.7rem;
    color: $gray-3;
    font-weight: 600;
    letter-spacing: $heading-spacing;

    .number {
      margin-left: 10px;
      font-size: 1.8rem;
      color: $gray-5;
      font-weight: 500;
    }
  }

  hr {
    margin: 20px 0;
    height: 1px;
    border: 0;
    background-color: $gray-2;
  }

  .button {
    margin-top: auto;
  }
}
.dashboard-content,
.user-content {
    position: relative;

    .background {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: url("#{$image-path}dashboard-bg.svg") center bottom no-repeat #F5F9FC;
        z-index: -1;
    }
}

.dashboard-content {
    order: 1;
}

.user-content {
    min-height: 100vh;
    top: 0;

    @include media-breakpoint-up(md) {
        top: 0;
    }

    .user-content-logo {
        padding-top: 20px;
        padding-bottom: 20px;

        @include media-breakpoint-up(md) {
            padding-top: 35px;
            padding-bottom: 50px;
        }

        @include media-breakpoint-down(sm) {
            padding-top: 100px;
        }

        img {
            @include media-breakpoint-down(xs) {
                max-width: 180px;
            }
        }
    }

    .user-content-vertical-holder {
        @include media-breakpoint-up(md) {
            position: absolute;
            width: 100vw;
            height: 100vh;
            left: 0;
            top: 0;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .user-content-horizontal-holder {
        max-width: 1030px;
    }
}

body.public {

    .user-content {

        .user-content-logo {
            @include media-breakpoint-down(sm) {
                padding-top: 30px;
            }
        }
    }
}

.hero-image {
    margin: 0 -15px 60px;
    width: calc(100% + 30px);
    text-align: center;
    height: 420px;
    color: $white;
    align-items: center;
    background-size: cover;

    @include media-breakpoint-down(sm) {
        height: 300px;
    }


    &__create-account {
        background: url("#{$image-path}hero-create-account.jpg") center no-repeat;
        background-size: cover;
    }

    &__select-package {
        background: url("#{$image-path}hero-select-package.jpg") center no-repeat;
        background-size: cover;
    }

    &__package-selection {
        background: url("#{$image-path}hero-package-selection.jpg") center no-repeat;
        background-size: cover;
    }

    h1 {
        color: $white;
        font-size: 48px;
        text-transform: none;
        font-family: $body-font;
        letter-spacing: 0;
        line-height: 1.2;

        @include media-breakpoint-down(sm) {
            font-size: 36px;
        }


        span {
            text-transform: capitalize;
        }
    }

    p {
        font-size: 28px;
        font-family: $body-font;
        line-height: 1;

        @include media-breakpoint-down(sm) {
            font-size: 24px;
        }
    }

    .cta {
        font-size: 18px;

        a {
            font-family: $body-font;
            color: $white;
            background-color: #00A2AE;
            min-width: 255px;
            height: 40px;
            padding: 0 12px;
            line-height: 40px;
            border-radius: 10px;

            &:hover {
                text-decoration: none;
                background-color: darken(#00A2AE, 10%);
            }
        }

        span {
            font-family: $body-font;
            font-style: italic;
        }
    }
}

.button.circle {
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.row.divided {
    & > div:not(:first-child) {
        @include media-breakpoint-down('md') {
            border-top: 1px solid $gray-2;
            padding-top: 8px;
            margin-top: 8px;
        }

        @include media-breakpoint-up('lg') {
            border-left: 1px solid $gray-2;
        }
    }
    @include media-breakpoint-up('lg') {
        padding: 0 16px;
    }
}
.package-price {
    color: $gray-5;
    font-size: 36px;
    font-weight: 600;
    letter-spacing: .14rem;
}

.badge {
  position: relative;
  padding: 8px 20px;
  display: inline-block;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: $label-spacing;
  background-color: $gray-2;
  color: $gray-4;
  font-size: .9rem;
  border-radius: 20px;

  &.orange {
    background-color: $light-orange;
    color: $orange;
  }
  &.green {
    background-color: $light-green;
    color: $green;
  }
  &.blue {
    background-color: $light-blue;
    color: $blue;
  }
  &.purple {
    background-color: $light-purple;
    color: $purple;
  }
  &.transparent {
    background-color: transparent;
  }
  &.bold {
    color: $white;
    background-color: $gray-4;

    &.orange {
      background-color: $orange;
    }
    &.green {
      background-color: $green;
    }
    &.blue {
      background-color: $blue;
    }
    &.purple {
      background-color: $purple;
    }
  }
  &.fixed {
    width: 130px;
    display: flex;
    justify-content: center;
  }
  &.small {
    text-align: center;
    font-size: .9rem;
    padding: 4px 12px;
    min-width: 36px;
  }
}
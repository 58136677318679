.gps-icon {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;

  background-size: 96%;
  background-repeat: no-repeat;
  background-color: transparent;
  background-position: center center;

  &.add {
    background-image: url('#{$image-path}add.svg');
  }
  &.trash {
    background-image: url('#{$image-path}delete.svg');

      &.white {
          background-image: url('#{$image-path}delete-white.svg');
      }
  }
  &.caret {
    background-image: url('#{$image-path}dropdown.svg');
    background-size: 24px;

    .primary &,
    &.primary {
      background-image: url('#{$image-path}dropdown-primary.svg');
    }
  }
  &.arrow {
    background-image: url('#{$image-path}right-arrow.svg');
  }
  &.arrow-left {
    transform: rotate(180deg);
    background-image: url('#{$image-path}right-arrow.svg');
  }
  &.arrow,
  &.arrow-left {
    &.gray {
      background-image: url('#{$image-path}right-arrow-gray.svg');
    }
  }
  &.download {
    background-image: url('#{$image-path}download.svg');
  }
  &.printer {
    background-image: url('#{$image-path}printer.svg');
  }
}

.dashboard-sidebar {
  order: 0;
  background: url('#{$image-path}sidebar-bg.svg') $white no-repeat;
  background-position: center bottom;
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(md) {
    @supports (position: sticky) {
      top: 0;
      left: 0;
      position: -webkit-sticky;
      position: sticky;
      z-index: 1000;
      height: 100vh;
    }
  }

  @include media-breakpoint-up(xl) {
    flex: 0 1 450px;
  }

  .dashboard-sidebar-nav {
    margin: auto -15px;

    ul {
      list-style: none;
      font-family: $display-font;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 1.15rem;
      letter-spacing: $heading-spacing;
      margin: 0;
      padding: 0;

      li {
        padding-left: 36px;

        &.active {
          position: relative;

          &:before {
            position: absolute;
            left: 0;
            content: '';
            height: 44px;
            width: 50px;
            background-color: $primary;
            border-top-right-radius: 22px;
            border-bottom-right-radius: 22px;
          }

          a {
            color: $primary;
            padding-left: 30px;
          }
        }
        a {
          display: inline-block;
          color: $gray-4;
          text-decoration: none;
          padding: 10px 4px;

          @include css3(transition, padding-left 0.2s ease);
        }
        &:not(.active) {
          a:hover {
            padding-left: 16px;
          }
        }
      }
    }

    .organization-details {
      border-top: 1px solid $gray-3;
      margin: 0 0 0 36px;
      padding-top: 30px;
    }
  }
}

.user-utility {

  .user-content & {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    background-color: rgba($light-blue, 0.8);
    z-index: 8999;


    @include media-breakpoint-up(md) {
      width: 300px;
      background-color: transparent;
    }
  }
    .language {
        font-family: $display-font;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 1.1rem;
        color: $gray-3;
        letter-spacing: $heading-spacing;
    }
  .user {
    font-family: $display-font;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.3rem;
    color: $primary;
    letter-spacing: $heading-spacing;

    small {
      display: block;
      font-size: .7rem;
    }
  }

  .menu-button.active,
  .active > .menu-button {
    .circle {
      &:nth-child(1) {
        transform: translateX(8px)  translateY(-14px);
      }
      &:nth-child(3) {
        transform: translateX(-8px) translateY(14px);
      }
    }
  }

  .menu-button {
    position: relative;
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;

    .circle {
      height: 8px;
      width: 8px;
      border-radius: 50%;
      background: $primary;

      &:nth-child(1),
      &:nth-child(3) {
        @include css3(transition, all 0.2s ease);
      }
      &:nth-child(1) {
        transform: translateX(-6px);
      }
      &:nth-child(3) {
        transform: translateX(6px);
      }
    }
  }
}

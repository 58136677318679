// Headings

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 14px;
  font-family: $display-font;
  font-weight: $heading-font-weight;
  color: $gray-4;
  letter-spacing: $heading-spacing;
}

h1, .h1 {
  font-family: $body-font;
  font-size: 1.5rem;
  color: $gray-5;
  text-transform: uppercase;
}

h2, .h2 {
  font-size: 1.25rem;
  font-weight: 500;
}

h3, .h3 {
  font-family: $body-font;
  font-size: 1.1rem;
  font-weight: 500;
  letter-spacing: $body-spacing;
}

.display {
  font-family: $display-font;
  font-weight: 400;
  font-size: 1.8rem;
  letter-spacing: .03rem;

  @include media-breakpoint-up(lg) {
    font-size: 2.4rem;
    letter-spacing: .06rem;
  }
}

a {
  color: $primary;
}

ul.check {
  list-style: none;
  padding-left: 14px;

  li {
    padding-left: 26px;
    margin-bottom: 7px;
    background: url('#{$image-path}check-bullet.svg') left 6px no-repeat;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &.primary li {
    background-image: url('#{$image-path}check-bullet-blue.svg');
  }
  &.danger li {
    background-image: url('#{$image-path}check-bullet-orange.svg');
  }
  &.success li {
    background-image: url('#{$image-path}check-bullet-green.svg');
  }
}
/*
*  Loader
*  =======================
*/

.loader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 500;
  background: rgba($gray-3, 0.8);

  display: flex;
  justify-content: center;
  align-items: center;

  .loading-text {
    position: relative;
    text-align: center;
    font-size: 3rem;
    color: $white;
    font-weight: 600;
    text-transform: uppercase;
  }
}

.typing_loader {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  -webkit-animation: typing 1s linear infinite alternate;
  -moz-animation: typing 1s linear infinite alternate;
  animation: typing 1s linear infinite alternate;
  margin: 46px auto;
  position: relative;
  left: -12px;

  &.light {
    -webkit-animation: typing-light 1s linear infinite alternate;
    -moz-animation: typing-light 1s linear infinite alternate;
    animation: typing-light 1s linear infinite alternate;
  }
  &.green {
    -webkit-animation: typing-green 1s linear infinite alternate;
    -moz-animation: typing-green 1s linear infinite alternate;
    animation: typing-green 1s linear infinite alternate;
  }
}

// keyframes mixin
@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}

@include keyframes(typing) {
  0%{
    background-color: rgba($red, 1);
    box-shadow: 12px 0px 0px 0px rgba($red,0.2),
    24px 0px 0px 0px rgba($red,0.2);
  }
  25%{
    background-color: rgba($red, 0.4);
    box-shadow: 12px 0px 0px 0px rgba($red,2),
    24px 0px 0px 0px rgba($red,0.2);
  }
  75%{ background-color: rgba($red, 0.4);
    box-shadow: 12px 0px 0px 0px rgba($red,0.2),
    24px 0px 0px 0px rgba($red,1);
  }
}

@include keyframes(typing-light) {
  0%{
    background-color: rgba($white, 1);
    box-shadow: 12px 0px 0px 0px rgba($white,0.2),
    24px 0px 0px 0px rgba($white,0.2);
  }
  25%{
    background-color: rgba($white, 0.4);
    box-shadow: 12px 0px 0px 0px rgba($white,2),
    24px 0px 0px 0px rgba($white,0.2);
  }
  75%{ background-color: rgba($white, 0.4);
    box-shadow: 12px 0px 0px 0px rgba($white,0.2),
    24px 0px 0px 0px rgba($white,1);
  }
}

@include keyframes(typing-green) {
  0%{
    background-color: rgba($green, 1);
    box-shadow: 12px 0px 0px 0px rgba($green,0.2),
    24px 0px 0px 0px rgba($green,0.2);
  }
  25%{
    background-color: rgba($green, 0.4);
    box-shadow: 12px 0px 0px 0px rgba($green,2),
    24px 0px 0px 0px rgba($green,0.2);
  }
  75%{ background-color: rgba($green, 0.4);
    box-shadow: 12px 0px 0px 0px rgba($green,0.2),
    24px 0px 0px 0px rgba($green,1);
  }
}
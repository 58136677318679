.progress {
  border-radius: 0.7rem;
  background-color: #F5F9FC;
}

.progress-bar {
  background-color: #E2EAEF !important;

  &.bg-primary {
    background-color: #C6F1FF !important;
  }
}
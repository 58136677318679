.score {
  display: inline-block;
  padding: 8px 20px;
  font-size: 0.9rem;
  font-weight: 700;

  &.orange {
    color: $orange;
  }
  &.green {
    color: $green;
  }
  &.blue {
    color: $blue;
  }
  &.purple {
    color: $purple;
  }
}
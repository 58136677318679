.modal {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  display: none;
  z-index: 9999;
  justify-content: center;
  align-items: center;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($gray-5, 0.6);
    z-index: -1;
  }
  &.visible {
    display: flex;
  }
}

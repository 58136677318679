// Input Groups
.form-group {
  margin-bottom: 1rem;

  &:last-of-type {
    margin-bottom: 0;
  }
}

// Form Inputs
.form-input {
  display: block;
  width: 100%;
  height: 44px;
  padding: 8px 14px;
  font-family: $body-font;
  //font-size: 1.05rem;
  font-weight: 500;
  color: $gray-4;
  letter-spacing: .03rem;
  border: 2px solid $gray-2;
  background-color: $gray-1;
  border-radius: 4px;

  @include css3(transition, all 0.2s ease);

  &::placeholder {
    opacity: 1;
    color: $gray-3;
  }
  &::-ms-expand {
    background-color: transparent;
    border: 0;
  }
  &:disabled,
  &[readonly],
  &:read-only {
    background-color: $gray-2;
    opacity: 1;
  }
  &[readonly],
  &:read-only {
    background-color: $gray-1;
    opacity: 1;
  }
  &:focus {
    outline: 0;
    border-color: $gray-3;
    background-color: $white;

    &[readonly],
    &:read-only {
      opacity: 1;
    }
  }
  &:disabled {
    cursor: no-drop;
    opacity: .6;
  }
  &.small {
    height: 28px;
    padding: 8px 14px;
    font-size: .8rem;
  }
}

textarea.form-input {
  min-height: 80px;
}

// Large Form Input
.form-input.large {
  height: 50px;
  padding: 12px 18px;
  font-family: $body-font;
  font-size: 1.05rem;
}

// Icon Form Inputs
.form-input.icon {
  padding-left: 50px;
  background-position: 10px center;
  background-repeat: no-repeat;

  &.search {
    background-image: url('#{$image-path}search.svg');
  }
}

// Checkbox and Radio Buttons
.form-check {
  position: relative;
  display: block;
}

input[type=checkbox],
input[type=radio] {
  box-sizing: border-box;
  padding: 0;

  @include css3(touch-action, manipulation);

  &:disabled {
    cursor: no-drop;

    & + .form-check-label {
      opacity: .8;
      cursor: no-drop;
    }
  }
}

input[type=checkbox].form-check-input,
input[type=radio].form-radio-input {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

.form-check-label {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  display: inline-block;
  height: 24px;
  line-height: 24px;
  font-size: 1rem;
  font-weight: 300;
  margin-bottom: 0;

  @include css3(user-select, none);

  &:before {
    content: '';
    position: absolute;
    left: 0px;
    top: 0px;
    width: 24px;
    height: 24px;
    z-index: 2;
    opacity: 0;
    border-radius: 50%;
    background-color: $primary;

    @include transform(scale(0));
    @include transition(transform 0.2s ease, opacity 0.15s ease, border-radius 0.15s ease);
  }
  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 24px;
    width: 24px;
    background-color: $gray-3;
    border-radius: 4px;
    z-index: 0;
  }

  &.small {
    height: 18px;
    line-height: 18px;
    font-size: .7rem;

    &:before,
    &:after {
      width: 18px;
      height: 18px;
    }
  }
}

input[type=checkbox].form-check-input:checked + label {
  &:before {
    opacity: 1;
    border-radius: 4px;
    @include transform(scale(1));
  }
}

// Select
select.form-input {

}

option:disabled {
  color: $gray-3;
}

// Validation
.form-input.is-invalid {
  border-color: $orange;
  background-color: $light-orange;
  color: $orange;
}

span.invalid-feedback {
  color: $orange;
  font-size: .9rem;
}